// Use this file to override bootstrap default variables
// For more information see: https://create-react-app.dev/docs/adding-bootstrap

// Override default variables before the import
// Default variables are defined in: '~bootstrap/scss/_variables.scss'
// $body-bg: #000;
$siemens-teal: #009999;

$theme-colors: (
  'siemens-teal': $siemens-teal,
  'primary': #2387aa,
);

// $colors: (
//   'siemens-teal': $siemens-teal,
// );

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;

$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;
$btn-font-weight: 700;
$btn-padding-y: 0.6rem;
$btn-padding-x: 0.9rem;

@font-face {
  font-family: 'SiemensSans';
  src: url('./assets/fonts/siemenssans-roman-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SiemensSans';
  src: url('./assets/fonts/siemenssans-bold-webfont.woff2') format('woff2');
  font-weight: 500 900;
  font-style: normal;
}

$font-family-sans-serif: 'SiemensSans', Arial, sans-serif;

$toast-font-size: 1rem;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
