.nav-tile-item {
  width: 48%;
  margin-bottom: 35px;
  display: flex;

  &__card {
    display: flex;
    align-items: center;
    border: 1px solid #000000;
    border-radius: 5px;
    padding: 30px;
    flex: 1;

    &--disabled {
      background-color: #dadada;
      opacity: 0.4;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  &__label {
    margin-bottom: 0;
  }
}
