.drop_zone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 250px;
  cursor: pointer;
  border: 2px dashed #ccc;
  border-radius: 6px;

  & > p {
    margin-right: auto;
    margin-left: auto;
  }
}

.selected_files {
  max-height: 240px;
  overflow-y: scroll;
}

.selected_file {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  & > img {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    object-fit: cover;
    margin-right: 10px;
  }
}
