

.slide {
	width: 100%;
	/*max-width: 920px;*/
	height: 690px;
	position: relative;
	border: 1px solid #333;
	/* background-color: #EFEFEF; */
	text-align: center;
	border-radius: 10px;
	/* box-shadow: 1px 1px 3px #AAA; */
	overflow: hidden;
	/*transform: scale(0.85);*/
}

.slide div.block {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
}

.slide div.content {
	display: flex;
	flex-direction: column;
	/*
	justify-content: center;
	align-items: flex-end;
	*/
}

.slide div.v-up {
	justify-content: flex-start;
}
.slide div.v-mid {
	justify-content: center;
}
.slide div.v-down {
	justify-content: flex-end;
}

.slide div.h-left {
	align-items: flex-start;
	text-align: left;
}
.slide div.h-mid {
	align-items: center;
	text-align: center;
}
.slide div.h-right {
	align-items: flex-end;
	text-align: right;
}

.slide div.up-left {
	justify-content: flex-start;
	align-items: flex-start;
	text-align: left;
}
.slide div.up-center {
	justify-content: flex-start;
	align-items: center;
}
.slide div.up-right {
	justify-content: flex-start;
	align-items: flex-end;
}
.slide div.center-left {
	justify-content: center;
	align-items: flex-start;
	text-align: left;
}
.slide div.center-center {
	justify-content: center;
	align-items: center;
}
.slide div.center-right {
	justify-content: center;
	align-items: flex-end;
}
.slide div.down-left {
	justify-content: flex-end;
	align-items: flex-start;
	text-align: left;
}
.slide div.down-center {
	justify-content: flex-end;
	align-items: center;
}
.slide div.down-right {
	justify-content: flex-end;
	align-items: flex-end;
}


.slide li.slide {
	margin: 10px 0px;
	font-size: 18px;
}

/* div.footer {
	text-align: center;
} */

.slide table {
	position: absolute;
}

.slide table, .slide th, .slide td {
	border: 1px solid black;
}

.slide svg.drawing {
	position: absolute;
	overflow: visible;
}
/*
#pptx-thumb {
	min-width: 240px;
	height: 180px;
}
*/