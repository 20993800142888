.info-column-header {
  text-align: center;
  margin-bottom: 25px;
  text-decoration: underline;
}

.input-disabled {
  background-color: #e9ecef;
  opacity: 1;
}
