.tui-image-editor-header {
  display: none;
}

.image-list {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow-x: scroll;

  & > * {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    margin-right: 10px;
    flex-shrink: 0;
  }
  & > div {
    background-color: #eeeeee;
  }
  & > img {
    object-fit: cover;
  }
}

.current-image-placeholder {
  height: 300px;
  background-color: #eeeeee;
}

.tui-image-editor-container
  .color-picker-control
  .tui-colorpicker-palette-button {
  border-radius: 0;
  margin: 1px;
  height: 20px;
  width: 5px;
}
