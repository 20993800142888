.network-status-light {
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  border-radius: 50%;

  &--online {
    background-color: #00ff00;
  }

  &--offline {
    background-color: #ff0000;
  }
}
