.kpi-column-header {
  text-align: center;
  min-height: 75px;
  text-decoration: underline;
}

.kpi-item {
  text-align: center;
  margin-bottom: 30px;
  padding: 10px;

  & svg {
    margin-bottom: 5px;
    color: var(--siemens-teal);
  }

  &-number {
    font-size: 1.8em;
    font-weight: bold;
  }

  &-title {
    font-size: 1em;
  }
}
